import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{style:({ 'margin-left': _vm.marginChange, 'margin-right': _vm.marginChange }),attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"7"}},[_c(VCard,{staticClass:"mt-12 mx-auto py-4 elevation-6"},[_c(VCard,{staticClass:"mx-auto my-n10 elevation-6",attrs:{"color":"blue-grey darken-4","max-width":"calc(100% - 32px)"}},[_c(VCardTitle,{staticClass:"font-weight-light white--text center"},[_vm._v(" "+_vm._s(_vm.$t(_vm.content.tabheading))+" ")])],1),_c(VCardText,{staticClass:"pt-12 pb-0"},[(_vm.content.heading)?_c('div',{staticClass:"title font-weight-light mb-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.content.heading))+" ")]):_vm._e(),_c('div',{staticClass:"subheading font-weight-light grey--text mt-2"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t(_vm.content.bodytext))+" ")])]),_c(VForm,[_c('form-builder',{key:_vm.keyID,attrs:{"schema":_vm.get_userToCompanySchema,"editable":_vm.get_userToCompanyEditable},on:{"update-form":_vm.updateUserToCompany},model:{value:(_vm.get_userToCompanyFormData),callback:function ($$v) {_vm.get_userToCompanyFormData=$$v},expression:"get_userToCompanyFormData"}})],1),_c('alertError',{staticClass:"mt-0"}),_c(VDivider,{staticClass:"my-2"}),_c(VRow,{attrs:{"row":"","no-gutters":""}},[_c(VCol,{class:_vm.$vuetify.breakpoint.xs ? "mr-4" : "mr-0",attrs:{"cols":"1","align-self":"center"}},[_c(VIcon,{attrs:{"dense":"","color":"primary"}},[_vm._v(" $mdiInformation ")])],1),_c(VCol,[_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t(_vm.content.footnote))+" ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }