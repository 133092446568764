<template>
  <v-row
    align="center"
    justify="center"
    :style="{ 'margin-left': marginChange, 'margin-right': marginChange }"
  >
    <v-col cols="12" sm="8" md="7">
      <v-card class="mt-12 mx-auto py-4 elevation-6">
        <v-card
          color="blue-grey darken-4"
          class="mx-auto my-n10 elevation-6"
          max-width="calc(100% - 32px)"
        >
          <v-card-title class="font-weight-light white--text center">
            {{ $t(content.tabheading) }}
          </v-card-title>
        </v-card>
        <v-card-text class="pt-12 pb-0">
          <div class="title font-weight-light mb-2" v-if="content.heading">
            {{ $t(content.heading) }}
          </div>
          <div class="subheading font-weight-light grey--text mt-2">
            <p>
              {{ $t(content.bodytext) }}
            </p>
          </div>
          <v-form>
            <form-builder
              v-bind:schema="get_userToCompanySchema"
              v-model="get_userToCompanyFormData"
              @update-form="updateUserToCompany"
              :editable="get_userToCompanyEditable"
              :key="keyID"
            />
          </v-form>
          <alertError class="mt-0" />
          <v-divider class="my-2"></v-divider>
          <v-row row no-gutters>
            <v-col
              cols="1"
              align-self="center"
              :class="$vuetify.breakpoint.xs ? `mr-4` : `mr-0`"
            >
              <v-icon dense color="primary">
                $mdiInformation
              </v-icon>
            </v-col>
            <v-col>
              <span class="text-caption">
                {{ $t(content.footnote) }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable no-console */
import FormBuilder from "../components/forms/FormBuilder";
import confUserToCompany from "../conf/confUserToCompany";
import AlertError from "../components/AlertError";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserToCompany",
  components: { FormBuilder, AlertError },
  title() {
    return this.$t(this.$route.meta.title);
  },

  data: () => ({
    content: confUserToCompany.content
  }),

  created() {
    this.companyList();
  },

  computed: {
    ...mapGetters("auth", [
      "get_userToCompanySchema",
      "get_userToCompanyFormData",
      "get_userToCompanyEditable"
    ]),
    keyID() {
      return this.$router.currentRoute.path.replace(/\//g, "_");
    }, //dynamic :key

    marginChange() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "0px";
        default:
          return "-12px";
      }
    }
  },

  methods: {
    ...mapActions("auth", ["updateUserToCompany", "companyList"])
  }
};
</script>

<style scoped></style>
